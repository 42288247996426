import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "fiscal-years"
    }}>{`Fiscal Years`}</h1>
    <p>{`When referring to fiscal years, use the following formats for quarter and year, or just year.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Q1`}</li>
        <li parentName="ul">{`FY17`}</li>
        <li parentName="ul">{`2017`}</li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      